var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: _vm.project.Uuid
								}
							}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item"},[(_vm.configuration)?_c('router-link',{attrs:{"to":{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")]):_vm._e()],1),(_vm.localization_project)?_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid,
							project_id: this.$route.params.project_id
						}
					}}},[_vm._v(_vm._s(_vm.localization_project.Name)+" ")])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Game content ")])])]),(_vm.loading)?_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):[(_vm.tokens.length === 0)?_c('div',{staticClass:"bg-light p-5 text-center"},[_c('h3',[_vm._v("No translation loaded for this game")]),_c('router-link',{staticClass:"btn btn-primary mt-3",attrs:{"to":{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-upload',
										params: {
											uuid: _vm.$route.params.uuid,
											cuuid: _vm.$route.params.cuuid,
											project_id: this.$route.params.project_id
											}
				}}},[_vm._v("Upload a locale file ")])],1):_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-outline-success px-5 py-3 my-4",attrs:{"disabled":_vm.saving},on:{"click":_vm.save}},[_vm._v("SAVE")])]),_c('localization-editor',{attrs:{"disabled":_vm.saving,"updated":_vm.updated},on:{"changed":_vm.changed},model:{value:(_vm.tokens),callback:function ($$v) {_vm.tokens=$$v},expression:"tokens"}}),(!_vm.updated)?_c('div',{staticClass:"p-5 bg-warning text-left text-white"},[_vm._v(" There are "+_vm._s(_vm.changedTokens.size)+" changed translations: "),_c('ol',_vm._l((_vm.changedTokens),function(token){return _c('li',[_vm._v(_vm._s(token))])}),0)]):_vm._e(),_c('button',{staticClass:"btn btn-outline-success px-5 py-3 my-4",attrs:{"disabled":_vm.saving},on:{"click":_vm.save}},[_vm._v("SAVE")])],1),_c('hr'),(_vm.localization_project)?_c('router-link',{staticClass:"btn btn-primary my-5",attrs:{"to":{
											name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
											params: {
												uuid: _vm.$route.params.uuid,
												cuuid: _vm.$route.params.cuuid,
												project_id: this.$route.params.project_id
												}
					}}},[_vm._v("Back to localization project "+_vm._s(_vm.localization_project.Name)+" ")]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }