<template>
  <div>

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{name: 'projects-index'}">Projects</router-link>
        </li>

        <li class="breadcrumb-item" v-if="project">
          <router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
        </li>

        <li class="breadcrumb-item">
          <router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}" v-if="configuration">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
          </router-link>
        </li>

        <li class="breadcrumb-item active" v-if="localization_project">
          <router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid,
							project_id: this.$route.params.project_id
						}
					}">{{ localization_project.Name }}
          </router-link>
        </li>

        <li class="breadcrumb-item active">
          Game content
        </li>

      </ol>
    </nav>

    <div v-if="loading" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <template v-else>

      <div class="bg-light p-5 text-center" v-if="tokens.length === 0">
        <h3>No translation loaded for this game</h3>
        <router-link :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-upload',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: this.$route.params.project_id
											}
				}" class="btn btn-primary mt-3">Upload a locale file
        </router-link>
      </div>
      <div v-else class="text-center">

        <div class="d-flex justify-content-end">
          <button class="btn btn-outline-success px-5 py-3 my-4" :disabled="saving" @click="save">SAVE</button>
        </div>

        <localization-editor :disabled="saving" :updated="updated" @changed="changed"
                             v-model="tokens"></localization-editor>

        <div class="p-5 bg-warning text-left text-white" v-if="!updated">
          There are {{ changedTokens.size }} changed translations:
          <ol>
            <li v-for="token in changedTokens">{{ token }}</li>
          </ol>
        </div>

        <button class="btn btn-outline-success px-5 py-3 my-4" :disabled="saving" @click="save">SAVE</button>
      </div>
      <hr>
      <router-link v-if="localization_project" :to="{
											name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
											params: {
												uuid: $route.params.uuid,
												cuuid: $route.params.cuuid,
												project_id: this.$route.params.project_id
												}
					}" class="btn btn-primary my-5">Back to localization project {{ localization_project.Name }}
      </router-link>
    </template>


  </div>
</template>

<script>
import api from '@/api'
import LocalizationEditor from "@/components/localizations/LocalizationEditor";
import messageBox from '@/components/modals/alert'

export default {
  name: "localization-content",
  components: {LocalizationEditor},
  data: () => ({
    tokens: [],
    loading: false,
    saving: false,
    project: null,
    configuration: null,
    localization_project: null,
    changedTokens: new Set(),
    mySetChangeTracker: 0
  }),
  async mounted() {
    this.loading = true
    this.project = await api.Projects.getProject(this.$route.params.uuid)
    this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
    this.localization_project = await api.Localizations.LocalizationProjects.get(this.$route.params.project_id)
    await this.loadTokens()
    this.loading = false
  },
  methods: {
    changed(token) {
      this.changedTokens.add(token)
      this.mySetChangeTracker += 1;
    },
    async loadTokens() {
      this.tokens = await api.Localizations.LocalizedContents.getJSON(this.$route.params.project_id)
    },
    async save() {
      this.saving = true
      await api.Localizations.LocalizationProjects.tokenBulkSave({
        LocalizationProjectfk: this.$route.params.project_id,
        Tokens: this.tokens.filter(t => this.changedTokens.has(t.ID)),
        overwrite: true
      })
      this.changedTokens.clear()
      this.mySetChangeTracker = 0
      messageBox({
        message: `Translation updated!`
      })
      await this.loadTokens()
      this.saving = false
    },
  },
  computed: {
    updated() {
      return (this.mySetChangeTracker >= 0) && this.changedTokens.size === 0
    }
  }
}
</script>

<style scoped>

</style>
